<template>
  <section class="aviso">
      <div class="container">   
        <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Aviso de <span>Privacidad</span> </h1>
            </div>
          </div>
          <h6>Actualizacion: Junio del 2022</h6>

                        <div class="row">

                            <div class="col-md-12 col-sm-12 col-lg-12">

                                

                                                

                                                

                                                <p itemprop="description"></p><p style="color:#000; font-size:18px; text-align:justify;"><span style="color:#006699; font-weight:700">CREDIX GS</span> S.A. de C.V. SOFOM E.N.R., con domicilio social en Periférico Paseo de la República número 2650 Piso 4, en la Colonia Prados del Campestre de la ciudad de Morelia, Michoacán, Código Postal 58297.

		<br>                            

		<br>
		Hace de su conocimiento que sus datos personales, incluso los sensibles y los patrimoniales o financieros, recabados o que se recaben, o generados con motivo de la relación jurídica que tengamos celebrada, o que, en su caso, se celebre, se tratarán exclusivamente para todos los fines vinculados con dicha relación, tales como: identificación, operación, administración, análisis, ofrecimiento y promoción de bienes, productos y servicios y/o prospección comercial, así como para cumplir las obligaciones derivadas de tal relación y otros fines compatibles o análogos, quedando convenido que usted acepta la transferencia que pudiera realizarse, en su caso, a las entidades que formen parte directa o indirectamente del grupo de empresas relacionadas con “<span style="color:#006699; font-weight:700">CREDIX</span>”, sus subsidiarias, proveedores de servicio o afiliadas. Asimismo, acepta y autoriza dicha transferencia para el caso en que se realice a terceros, nacionales o extranjeros (entidades o personas distintas del grupo de empresas asociadas con “<span style="color:#006699; font-weight:700">CREDIX</span>”), reconociendo que, bajo ningún supuesto o circunstancia serán compartidos sus datos sensibles, patrimoniales o financieros. En adición a lo anterior, en caso de que se requiera utilizarlos para una finalidad que no se encuentre vinculada con la relación jurídica o de negocios antes señalada, la misma le será comunicada mediante la modificación al presente Aviso de Privacidad, el cual le será puesto a su disposición nuevamente, en el tiempo y forma que correspondan.
		

		<br>

		<br>Para limitar el uso y divulgación de sus datos, mantendremos políticas, procedimientos y controles de seguridad y confidencialidad de la información.
		<br>

		<br>
												
		El ejercicio de los derechos de acceso, rectificación, cancelación, oposición y la revocación del consentimiento deberá realizarse por escrito a la dirección citada, o, en su caso, a través de la dirección de correo electrónico <span style="color:#006699; font-weight:700">unes@credix.com.mx</span> misma que es atendida y administrada por la Dirección de Control y Cumplimiento.

		<br>

		

		<br>

		<br>El presente Aviso de Privacidad, así como sus modificaciones, estarán a su disposición en la página <span style="color:#006699; font-weight:700">www.credix.com.mx</span> y a través de comunicados colocados en nuestras oficinas y sucursales o informados mediante cualquier medio de comunicación que tengamos con usted.

		<br>

		<br>Finalmente, para el caso de haber proporcionado datos personales sensibles, patrimoniales o financieros, de otros titulares por conducto suyo, del mismo modo usted acepta y reconoce la obligación de hacerles de su conocimiento el presente Aviso de Privacidad a dichos titulares, o bien, indicarles los lugares en donde se encuentra a su disposición el referido Aviso, así como de informarles que ha proporcionado tales datos a <span style="color:#006699; font-weight:700">CREDIX GS</span> S.A. de C.V. SOFOM E.N.R.
	<br><br>
					En caso de ser aplicable, igualmente usted acepta y reconoce que estará obligado a proporcionar su propio Aviso de Privacidad.
</p><p></p>

                                            
                                         
                            </div>

                        </div>

                    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.aviso{
    margin:3rem 0;
}
</style>