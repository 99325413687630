import Home from '@/views/Home.vue'
import Index from '@/views/index.vue'
import Nosotros from '@/views/Nosotros.vue'
import Une from '@/views/une.vue'
import Solicitud from '@/views/solicitud/Solicitud.vue'
import Noapto from '@/views/solicitud/NoApto.vue'
import Fin from '@/views/solicitud/Fin.vue'
import Brokers from '@/views/solicitud/Brokers.vue'
import FormBRoker from '@/views/solicitud/FormBrokers.vue'
import Revolvente from '@/views/Servicios/revolvente.vue'
import Construcredix from '@/views/Servicios/construcredix.vue'
import Simple from '@/views/Servicios/simple.vue'
import Factoraje from "@/views/Servicios/factoraje.vue"
import Factorajepp from '@/views/Servicios/factorajepp.vue'
import Fideicomiso from '@/views/Servicios/fideicomiso.vue'
import FideicomisoInm from '@/views/Servicios/fideicomisoInmobiliaria.vue'
import Aviso from '@/views/aviso.vue'
import Faq from '@/views/faq.vue'
import Normativa from '@/views/Normativa.vue'
import Solicitud2 from '@/views/solicitud/solicitud2.vue'
import Solicitud3 from '@/views/solicitud/solicitud3.vue'
import Solicitud4 from '@/views/solicitud/solicitud4.vue'
import Solicitud5 from '@/views/solicitud/solicitud5.vue'
import Test from '@/views/test/load.vue'
import Simulador from '@/views/solicitud/simulador.vue'
import Cerrar from '@/views/cerrar.vue'
export default[
    {
        path:'/',
        component: Home,
        children:[
            {
                path:"/",
                component: Index
            },
            {
                path:"/nosotros",
                component: Nosotros
            },
            {
                path:"/une",
                component: Une
            },
            {
                path:"/solicitar-credito",
                component: Solicitud
            },
            {
                path:"/solicitud/lo-sentimos",
                component: Noapto
            },
            {
                path:"/solicitud/completado",
                component:Fin
            },
            
            {
                path:'/alta-brokers',
                component:Brokers
            },
            {
                path:'/brokers/alta',
                component:FormBRoker
            },
            {
                path:"/credito-revolvente",
                component:Revolvente
            },
            {
                path:"/credito-construcredix",
                component:Construcredix
            },
            {
                path:"/credito-simple",
                component: Simple
            },
            {
                path:"/factoraje-financiero",
                component:Factoraje
            },
            {
                path:"/factoraje-pago-a-proveedores",
                component: Factorajepp
            },
            {
                path:"/fideicomiso-de-garantia-credito",
                component:Fideicomiso
            },
            {
                path:"/fideicomiso-de-garantia-desarrollo-inmobiliario",
                component: FideicomisoInm
            },
            {
                path:'/aviso-de-privacidad',
                component:Aviso
            },
            {
                path:'/preguntas-frecuentes',
                component: Faq
            },
            {
                path:'/normativa',
                component: Normativa
            },
            {
                path:'/solicitar-fideicomiso-credito',
                component: Solicitud2
            },
            {
                path:'/solicitar-fideicomiso-de-garantia-desarrollo-inmobiliario',
                component:Solicitud3
            },
            {
                path:'/solicitar-factoraje-financiero',
                component:Solicitud4
            },
            {
                path:'/solicitar-factoraje-pago-proveedores',
                component: Solicitud5
            },
            {
                path:'/test',
                component: Test
            },
            {
                path:'/simular-credito',
                component:Simulador
            },
            {
                path:'/cerrar-sesion',
                component:Cerrar
            }
        ]
    }
]