<template>
<div>
<section class="header2" id="head2" v-scrollanimation>
    <div class="imgLogo">
     <a href="/"> <img src="https://credix.com.mx/plantillav2/images/logo-2.png" alt="Logo Credix" width="150"></a>
    </div> 
  </section>
  <section class="login-form">
    <div class="sec-rig"  v-scrollanimation>
        <div class="container-form" v-if="seEnvio == 1">
          <div>
            <h1>Recupera Contraseña</h1>
          <div class="grupo-input">
            <p>Ingresa tu usuario </p>
            <div class="input">
              <div class="icon">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z" />
                </svg>  
              </div>
              <input type="text" v-model="mUser" >
            </div>
          </div>
          <div class="grupo-input">
            <p>Ingresa tu correo </p>
            <div class="input">
              <div class="icon">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z" />
                </svg>  
              </div>
              <input type="text" v-model="mCorreo" @keyup.enter="recuperar()">
            </div>
          </div>
          <div class="errores" v-if="errorCoincidencia">
            <p>No se encontraron coincidencias, por favor intente de nuevo.</p>
          </div>
          <div class="errores" v-if="errores.length">
            <ul>
              <li v-for="(error,index) in errores" :key="index">{{ error }}</li>
            </ul>
          </div>
          <div class="grupo-input">
            <button class="btn-credix" @click="recuperar">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M10 13C7.33 13 2 14.33 2 17V20H13.09C13.07 19.86 13.05 19.73 13.04 19.59C13 19.4 13 19.2 13 19C13 18.69 13.03 18.39 13.08 18.1C13.21 17.21 13.54 16.38 14 15.66C14.21 15.38 14.42 15.12 14.65 14.88L14.67 14.85C14.9 14.61 15.16 14.39 15.43 14.19C14.76 13.88 14 13.64 13.26 13.45C12.07 13.15 10.89 13 10 13M11.05 18.1H3.9V17C3.9 16.36 7.03 14.9 10 14.9C10.68 14.9 11.36 15 12 15.11C11.5 16 11.18 17 11.05 18.1M10 12C12.21 12 14 10.21 14 8S12.21 4 10 4 6 5.79 6 8 7.79 12 10 12M10 6C11.11 6 12 6.9 12 8S11.11 10 10 10 8 9.11 8 8 8.9 6 10 6M20 22V20H16V18H20V16L23 19L20 22Z" />
                </svg>
             &nbsp; Recuperar</button>
          </div>
          </div>
          <div class="grupo-input">
            <div class="forget">
              <router-link to="/iniciar-sesion">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M14.28,14.08L12.26,16.1C11.66,16.7 10.87,17 10.08,17C9.29,17 8.5,16.7 7.9,16.1C6.7,14.9 6.7,12.95 7.9,11.74L9.15,10.5L9.14,11.06C9.14,11.5 9.21,11.95 9.36,12.37L9.41,12.5L9.04,12.87C8.76,13.15 8.6,13.53 8.6,13.92C8.6,14.32 8.76,14.69 9.04,14.97C9.6,15.53 10.57,15.53 11.13,14.97L13.14,12.96C13.43,12.67 13.58,12.3 13.58,11.91C13.58,11.5 13.43,11.14 13.15,10.86C13,10.71 12.9,10.5 12.9,10.29C12.9,10.08 13,9.88 13.15,9.73C13.45,9.42 14,9.43 14.28,9.73C14.86,10.31 15.18,11.08 15.18,11.9C15.18,12.73 14.86,13.5 14.28,14.08M17.1,11.26L15.85,12.5L15.86,11.94C15.86,11.5 15.79,11.06 15.64,10.64L15.6,10.5L15.96,10.13C16.25,9.85 16.4,9.5 16.4,9.08C16.4,8.69 16.25,8.32 15.97,8.04C15.4,7.47 14.43,7.47 13.87,8.04L11.86,10.05C11.58,10.33 11.42,10.7 11.42,11.1C11.42,11.5 11.57,11.86 11.86,12.14C12,12.29 12.1,12.5 12.1,12.71C12.1,12.93 12,13.13 11.85,13.28C11.7,13.44 11.5,13.5 11.29,13.5C11.09,13.5 10.88,13.43 10.72,13.28C9.5,12.08 9.5,10.12 10.72,8.92L12.74,6.9C13.95,5.7 15.9,5.7 17.1,6.9C17.68,7.5 18,8.26 18,9.08C18,9.9 17.68,10.68 17.1,11.26Z" />
              </svg>
              <span class="ms-2">Acceder a mi cuenta</span> </router-link>
            </div>
          </div>
        </div>
        <div class="container-form loading" v-if="iscargando">
            <Loading texto="enviando"></Loading>
        </div>
         <div class="container-form" v-if="seEnvio == 2">
          <div>
            <h1><b>¡Listo!</b> </h1>
          <div class="grupo-input">
            <p>Hemos enviado un correo electrónico a <b>{{censurarCorreo(mCorreo)}}</b>, por favor sigue los pasos para continuar.</p>
            
          </div>
          </div>
          
          <div class="grupo-input">
            <div class="forget">
              <router-link to="/iniciar-sesion">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M14.28,14.08L12.26,16.1C11.66,16.7 10.87,17 10.08,17C9.29,17 8.5,16.7 7.9,16.1C6.7,14.9 6.7,12.95 7.9,11.74L9.15,10.5L9.14,11.06C9.14,11.5 9.21,11.95 9.36,12.37L9.41,12.5L9.04,12.87C8.76,13.15 8.6,13.53 8.6,13.92C8.6,14.32 8.76,14.69 9.04,14.97C9.6,15.53 10.57,15.53 11.13,14.97L13.14,12.96C13.43,12.67 13.58,12.3 13.58,11.91C13.58,11.5 13.43,11.14 13.15,10.86C13,10.71 12.9,10.5 12.9,10.29C12.9,10.08 13,9.88 13.15,9.73C13.45,9.42 14,9.43 14.28,9.73C14.86,10.31 15.18,11.08 15.18,11.9C15.18,12.73 14.86,13.5 14.28,14.08M17.1,11.26L15.85,12.5L15.86,11.94C15.86,11.5 15.79,11.06 15.64,10.64L15.6,10.5L15.96,10.13C16.25,9.85 16.4,9.5 16.4,9.08C16.4,8.69 16.25,8.32 15.97,8.04C15.4,7.47 14.43,7.47 13.87,8.04L11.86,10.05C11.58,10.33 11.42,10.7 11.42,11.1C11.42,11.5 11.57,11.86 11.86,12.14C12,12.29 12.1,12.5 12.1,12.71C12.1,12.93 12,13.13 11.85,13.28C11.7,13.44 11.5,13.5 11.29,13.5C11.09,13.5 10.88,13.43 10.72,13.28C9.5,12.08 9.5,10.12 10.72,8.92L12.74,6.9C13.95,5.7 15.9,5.7 17.1,6.9C17.68,7.5 18,8.26 18,9.08C18,9.9 17.68,10.68 17.1,11.26Z" />
              </svg>
              <span class="ms-2">Acceder a mi cuenta</span> </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="sec-left">
       
      </div>
    
      
  </section>
</div>

</template>

<script>
import Loading from '@/components/Loading/puntos2.vue' 
import axios from 'axios';

export default {
  components:{
    Loading
  },
  data(){
    return{
      seEnvio:1,
      mUser:"",
      mCorreo:"",
      iscargando:false,
      errorCoincidencia:false,
      errores:[]
    }
  },
  methods:{
    censurarCorreo(correo){
      let chars = 3; 
      let res = correo.replace(/[a-z0-9\-_.]+@/ig, (c) => c.substr(0, chars) + c.split('').slice(chars, -1).map(() => '*').join('') + '@')
      return res;
    },
    recuperar(){
       this.errores = [];
      if(this.mUser == ""){
        this.errores.push("Escribe un usuario.")
      }
      if(this.validarEmail(this.mCorreo)){
          this.iscargando = true
          this.seEnvio = 0;
          var formu = new FormData();
          formu.append("usr",this.mUser)
          formu.append("correo",this.mCorreo)
          formu.append("key",this.getKey())
          axios.post('https://credix.com.mx/api/front/olvido.php',formu)
          .then( (response) => {
            if(response.status==200){
              console.log(response)
              if(response.data.code == 1){
                setTimeout(()=>{
                    this.iscargando = false;
                    this.seEnvio = 2;
                },2000)
                
              }else{
                this.iscargando = false
                this.seEnvio = 1
                this.errorCoincidencia = true
              }
            }
          })
          .catch((error)=>{
            this.iscargando = false;
            this.seEnvio = 1;
            console.log(error);
          });

      }else{
        this.errores.push("Escribe un correo válido.")
      }
    },
    validarEmail(email){
      return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    getKey(){
      var crypto = require('crypto')
      var shasum = crypto.createHash('sha1')
      var dateObj = new Date();
      var month = String(dateObj.getMonth() + 1).padStart(2, '0');
      var day = String(dateObj.getDate()).padStart(2, '0');
      var year = dateObj.getUTCFullYear();
      var completeKey = "credix"+ year + month  + day;
      shasum.update(completeKey)
      let key = shasum.digest('hex')
      return key
    }
  }
}
</script>

<style lang="css" scoped>
.errores{
  background-color: #d0022150;
  color: #d00222;
  padding: 0.6rem 1rem;
  margin-bottom: 1rem;
  border-radius: 15px;
}
.errores p{
  margin: 0;
}
.errores ul{
  list-style: none;
  margin-bottom: 0rem;
}
.errores ul li{
  font-size: 9pt;
}
.header2#head2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 35%;
  padding: 0. 1rem;
}
.header2#head2 .imgLogo {
  padding-left: 2rem;
}
.container-form {
  width: 400px;
}
.container-form .input {
  border: 1px solid #4e4e4e;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
}
.container-form input {
  margin-left: 20px;
  width: 100%;
  border: none;
}
.container-form input:focus-visible {
  border: none;
  outline: none;
}
.login-form {
  width: 100%;
  background: #006699;
  height: 100vh;
  display: flex;
}
.login-form .sec-left {
  width: 63%;
  height: 100%;
  color: #fff;
  background-image: url('~@/assets/estrella.jpg');
  background-size: cover;
  background-position: center;
  background-color: #006699;
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  justify-content: center;
  align-items: center;
}
.login-form .sec-left .itemben {
  display: flex;
  margin: 1rem 0;
}
.login-form .sec-left .itemben h5 {
  margin-left: 20px;
  font-weight: lighter;
}
.login-form .sec-rig {
  width: 38%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form .sec-rig h1 {
  color: #006699;
  font-weight: 300;
  font-size: 28px;
}
.grupo-input {
  margin-bottom: 1rem;
}
.grupo-input a {
  text-decoration: none;
  color: #006699;
}
.grupo-input p {
  margin-bottom: 5px;
}
.grupo-input .forget {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen 
  and (min-width: 768px)
  and (max-width: 1100px){
.login-form .sec-rig {
  width: 90%;
}
}

@media screen and (max-width: 768px) {
  .header2#head2 {
    width: 100%;
    padding: 1rem 1rem;
    background: none;
    z-index: 99;
  }
  .header2#head2 .imgLogo {
    text-align: center;
    padding-left: 0rem;
  }
  .login-form {
    height: 100%;
    background-size: cover;
    background-image: url('~@/assets/estrella.jpg');
  }
  .login-form .sec-left {
    display: none;
  }
  .login-form .sec-rig {
    width: 90%;
    height: 100vh;
    margin: auto auto;
    padding: 0 2rem;
  }
  .login-form .sec-rig .container-form {
    display: block;
  }
  .login-form .sec-rig .container-form .formulario {
    padding: 1.3rem 1.;
    height: 100%;
    display: block;
  }
  .login-form .sec-rig .container-form .formulario .pasos {
    display: block;
    margin-bottom: 20rem;
    height: auto;
  }
  .login-form .sec-rig .container-form .formulario .pasos .paso.activo {
    height: 100%;
  }
  .login-form .sec-rig .container-form .footerform {
    padding: 10px 0;
    z-index: 999;
    position: fixed;
    bottom: 0px;
    display: block;
    left: 0;
    background-color: #fff;
    width: 100%;
  }
  .login-form .sec-rig .container-form .footerform .step {
    float: left;
    text-align: center;
    margin: 0;
    width: 25%;
  }
}



</style>