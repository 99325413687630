<template>
    <div class="home">
        <section class="container-fluid pt-5 bk-gris bkStar" v-scrollanimation>
        <div class="row">
          <div class="col-md-5">
            <div class="inicio-banner">
                <img class="iniciobane" src="@/assets/persona2.png" alt="" >
            </div>
            
          </div>

          <div class="col-md-7 d-flex align-items-center justify-content-center">
            <div class=" texto-inicio-banner">
                <p>Crédito con garantía inmobiliaria</p>
                <h1>Hasta por el 50% <br> del valor del inmueble</h1>
                <h2>Confiamos en ti, haz crecer tu negocio y transforma tu vida  con <br> nuestras soluciones financieras a la medida de tus necesidades.</h2>
                <router-link to="/solicitar-credito"> <button class="btn-credix">Solicitar financiamiento</button></router-link>
            </div>
          </div>
        </div>
      </section>
<section class="servicios" id="servicios"  v-scrollanimation>
        <div class="container">
          <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Nuestros <span>Servicios</span> </h1>
            </div>
          </div>
          <div class="content-titulo">
              <p>Desde una línea de <span>crédito</span>, la realización de <span>factoraje</span> o bien el establecimiento de un <span>fideicomiso de garantía</span>, hazlo posible con nosotros. </p>
          </div>

              <div class="row d-flex align-items-end mb-5">
                
                <div class="col-md-4">
                  <div class="servicio-card">
                    <h2 class="title-servicio">CRÉDITO</h2>
                    <hr class="hr-servicios">
                    <p>Ya sea un crédito simple, con revolvencia o bien uno especializado para construcción, tenemos el capital para ti. </p>
                    <div class="btn-lineas">
                      <select class="btn-credix-outline verLineas" @change="verLinea()" v-model="mLineas">
                        <option value="">Ver Líneas</option>
                        <option value="1">Crédito Revolvente</option>
                        <option value="2">Crédito Simple</option>
                        <option value="3">Crédito Construcredix</option>
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                        </svg>
                      </select>
                    </div>
                  </div>
                </div>
              
                <div class="col-md-4">
                  <div class="servicio-card">
                    <h2 class="title-servicio">FACTORAJE</h2>
                    <hr class="hr-servicios">
                    <p>Adelanta el cobro o el pago de tus facturas. No esperes 30, 60 o 120 días para tener flujo. Obtén liquidez inmediata aquí. </p>
                    <div class="btn-lineas">
                      <select class="btn-credix-outline verLineas" @change="verLinea()"  v-model="mLineas">
                        <option value="">Ver Factoraje </option>
                        <option value="4">Factoraje Financiero</option>
                        <option value="7">Factoraje Pago Proveedor</option>
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                        </svg>
                      </select>
                    </div>
                  </div>
                </div>

              
                <div class="col-md-4">
                  <div class="servicio-card">
                    <h2 class="title-servicio">FIDEICOMISO DE GARANTÍA</h2>
                    <hr class="hr-servicios">
                    <p >Respalda y blinda todas tus operaciones de crédito, de desarrollo inmobiliario o cualquier otra que necesite una garantía.</p>
                    <div class="btn-lineas">
                      <select class="btn-credix-outline verLineas" @change="verLinea()"  v-model="mLineas">
                        <option value="">Ver Fideicomisos </option>
                        <option value="5">Fideicomiso de Garantía para Créditos</option>
                        <option value="6">Fideicomiso de Garantía para Desarrollos Inmobiliarios</option>
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                        </svg>
                      </select>
                    </div>
                    
                  </div>
                </div>
              </div>
            

        </div>
      </section>
      <section class="pb-5 respaldo" v-scrollanimation>
        <div class="container">
          <div class="textos-respaldo text-center">
            <div class="textos-respaldo-inicial">
              <h1>Credix es una financiera sólida, segura y confiable.</h1>
              <h2>Siéntete tranquilo y seguro. Cumplimos con todos los marcos regulatorios y normativas legales, siendo estas instituciones mexicanas que nos supervisan. </h2>
              <span>¿Tienes alguna consulta, reclamación o aclaración?</span><router-link to="/une"><button>Contactar a la UNE</button></router-link>
           </div>
            <div class="textos-respaldo-imagen">
                <img src="@/assets/icons/CNBV.svg" alt="" height="50">
                <img src="@/assets/icons/ASOFOM.svg" alt=""  height="50">
                <img src="@/assets/icons/Condusef.svg" alt=""  height="50">
                <img src="@/assets/icons/Buro.svg" alt=""  height="50">
                <img src="@/assets/icons/banxico.svg" alt=""  height="50">
            </div>
          </div>
        </div>
      </section>

      <section class="beneficios mt-5">
        <div class="container">
          <div class="titulos-iniciales ">
            <div class="titulo">
                <h1>Crédito con <span>garantía inmobiliaria</span> </h1>
            </div>
          </div>
          
          <div class="row justify-content-center mt-4">
            <div class="row w-100">
              <div class="col-md-8">
                <div class="content-titulo">
                    <p>Solo necesitas algún bien inmueble como garantía: casa, terreno, departamento, oficinas o edificio. Al estar respaldado obtendrás más dinero a mejores plazos.</p>
                    <p>Nuestros beneficios son:</p>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="beneficio d-flex align-items-center">
                      <img style="width:50px" src="https://www.credix.com.mx/plantillav2/images/icon1.png" alt="">
                      <p> Financiamientos hasta por 50% del valor de tu inmueble<sup>1</sup></p>
                    </div>
                    <div class="beneficio d-flex align-items-center">
                      <img style="width:50px" src="https://www.credix.com.mx/plantillav2/images/icon12.png" alt="">
                      <p> Pre-calificación en 24 horas</p>
                    </div>
                    <div class="beneficio d-flex align-items-center">
                      <img style="width:50px" src="https://www.credix.com.mx/plantillav2/images/icon3.png" alt="">
                      <p> Fondeo disponible en 8 días<sup>2</sup></p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="beneficio d-flex align-items-center">
                      <img style="width:50px" src="https://www.credix.com.mx/plantillav2/images/icon4.png" alt="">
                      <p> Buró de crédito NO determinante</p>
                    </div>
                    <div class="beneficio d-flex align-items-center">
                      <img style="width:50px" src="https://www.credix.com.mx/plantillav2/images/icon-1.png" alt="">
                      <p> Sin comisiones de prepago</p>
                    </div>
                    <div class="beneficio d-flex align-items-center">
                      <img style="width:50px" src="https://www.credix.com.mx/plantillav2/images/icon-2.png" alt="">
                      <p> Sin plazos mínimos</p>
                    </div>
                  </div>
                </div>
                <div class="restric">
                  <p>
                    <sup>1</sup>Sujeto a aprobación y propuesta del comité de crédito. <br>
                    <sup>2</sup>Tiempo estimado promedio considerado a partir de contar con toda la documentación requerida.
                  </p>
                </div>
                <div class="mb-5 mt-3">
                 <router-link to="/solicitar-credito">   <button class="btn-credix">Solicitar ahora</button></router-link>
                </div>
              </div>
              <div class="col-md-4"> 
              <div class="img brnImg">
                <img src="@/assets/persona4.png" alt="" style="height:450px">
              </div>
            </div>
            </div>
            
            
          </div>
        </div>
      </section>
 <section class="my-5">
        <div class="container">
          <div class="titulos-iniciales ">
            <div class="titulo">
                <h1>Nuestro Proceso:<span> seguro y rápido</span> </h1>
            </div>
          </div>
          <div class="pasos-cuadro">
            <div class="cuadro-pasos">
              <div class="paso">
                <div class="paso-icon">
                  <img src="@/assets/icons/Precalificacion.svg" height="50" alt="">
                </div>
                <div class="paso-titulo">
                  <p>Paso 1 
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                    </svg>
                  </p>
                  <p>PRECALIFICACIÓN <br> DE GARANTÍA</p>
                </div>
                
              </div>
              <div class="paso">
                <div class="paso-icon">
                  <img src="@/assets/icons/doc.svg" height="50" alt="">
                </div>
                <div class="paso-titulo">
                  <p>Paso 2 
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                    </svg>
                  </p>
                  <p>DOCUMENTACIÓN <br> Y ANÁLISIS</p>
                </div>
              </div>
              <div class="paso">
                <div class="paso-icon">
                  <img src="@/assets/icons/propuesta.svg" height="50" alt="">
                </div>
                <div class="paso-titulo">
                  <p>Paso 3 
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                    </svg>
                  </p>
                  <p>PROPUESTA <br> DE CRÉDITO</p>
                </div>
              </div>
              <div class="paso">
                <div class="paso-icon">
                  <img src="@/assets/icons/firma.svg" height="50" alt="">
                </div>
                <div class="paso-titulo">
                  <p>
                  </p>
                  <p>¡LISTO!</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-5 bg-gris" >
        <div class="container">
          <div class="comienza-banner mb-5">
            <h2>Descubre nuestras soluciones financieras</h2>
          </div>
          <vueper-slides
          class="no-shadow"
          :autoplay="true"
          :touchable = "false" 
          :fixed-height="destaH()"
          :visible-slides="3"
          :breakpoints="{1200:{visibleSlides:3},800: { visibleSlides: 1 } }"
          >
              <vueper-slide>
                <template #content>
                  <div class="slide">
                    <div class="card">
                      <div class="card-body">
                        <div class="text-head">
                          <div>
                            <img src="@/assets/icons/CREDITOs.svg" alt="">
                          </div>
                          <div class="texto">
                          <p>Crédito</p>
                            <h3> Simple</h3>
                          </div>
                        </div>
                        <p class="sdescripcion">
                        Simple y rápido como lo necesitas, para que nada te detenga.
                        </p>
                      </div>
                      <router-link to="/credito-simple">
                      <div class="card-footer">
                        Ver más
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                        </svg>
                      </div>
                      </router-link>
                    </div>
                  </div>
                </template>
              </vueper-slide>
              <vueper-slide>
    <template #content>
    <div class="slide">
      <div class="card">
        <div class="card-body">
          <div class="text-head">
            <div>
              <img src="@/assets/icons/CONSTRUCREDIX.svg" alt="">
            </div>
            <div class="texto">
            <p>&nbsp;</p>
              <h3> Construcredix</h3>
            </div>
          </div>
          <p class="sdescripcion">
       El impulso que necesitas para tus proyectos de construcción
          </p>
        </div>
        <router-link to="/credito-construcredix">
        <div class="card-footer">
           Ver más
           <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
           </svg>
        </div>
        </router-link>
      </div>
    </div>
    </template>
              </vueper-slide>
              <vueper-slide>
    <template #content>
      <div class="slide">
        <div class="card">
          <div class="card-body">
            <div class="text-head">
              <div>
                <img src="@/assets/icons/FACTORAJEF.svg" alt="">
              </div>
              <div class="texto">
              <p>Factoraje</p>
                <h3> Financiero</h3>
              </div>
            </div>
            <p class="sdescripcion">
              Anticipa el pago de tus facturas y fortalece tu ciclo operativo.
            </p>
          </div>
          <router-link to="/factoraje-financiero">
          <div class="card-footer">
            Ver más
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </div>
          </router-link>
        </div>
      </div>
    </template>
  </vueper-slide>
  <vueper-slide>
    <template #content>
      <div class="slide">
        <div class="card">
          <div class="card-body">
            <div class="text-head">
              <div>
                <img src="@/assets/icons/FGDI.svg" alt="Fideicomiso de Garantía">
              </div>
              <div class="texto">
              <p>Fideicomiso de Garantía </p>
                <h3> Desarrollos Inmobiliarios</h3>
              </div>
            </div>
            <p class="sdescripcion">
        La manera más ágil, segura y transparente de asociarse y desarrollar
            </p>
          </div>
          <router-link to="/fideicomiso-de-garantia-desarrollo-inmobiliario">
          <div class="card-footer">
            Ver más 
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </div>
          </router-link>
        </div>
      </div>
    </template>
  </vueper-slide>
   <vueper-slide>
    <template #content>
      <div class="slide">
        <div class="card">
          <div class="card-body">
            <div class="text-head">
              <div>
                <img src="@/assets/icons/FGC.svg" alt="">
              </div>
              <div class="texto">
              <p>Fideicomiso </p>
                <h3> Garantía de Crédito</h3>
              </div>
            </div>
            <p class="sdescripcion">
              Asegura el cumplimiento y pago de operaciones de crédito
            </p>
          </div>
          <router-link to="/fideicomiso-de-garantia-credito">
          <div class="card-footer">
            Ver más 
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </div>
          </router-link>
        </div>
      </div>
    </template>
  </vueper-slide>
          </vueper-slides>
        </div>
      </section>
      <section class="py-5">
        <div class="container">
         <div class="titulos-iniciales ">
            <div class="titulo">
                <h1>hagamos <span> sinergia</span> </h1>
            </div>
          </div>
          
        </div>
      </section>
      <section class="brokers">
        <div class="container">
            <div class="row">
              <div class="col-md-6 brokers-textos">
                <h1>Únete a nuestro gran equipo de Brókers.</h1>
                <p>Estamos buscando alianzas que nos permitan seguir creciendo y tú puedes ser parte de esto.</p>
                
                <router-link to="/brokers/alta"><button class="btn-credix">Registrarme ahora</button></router-link>
              </div>
              <div class="col-md-6 mobitembor">
                <div class="itemsBr">
                  <div class="brItem">
                    <div class="imgBr">
                      <img src="@/assets/cash.png" alt="">
                    </div>
                    <p>Atractivas comisiones</p>
                  </div>
                  <div class="brItem">
                    <div class="imgBr">
                      <img src="@/assets/time.png" alt="">
                    </div>
                    <p>Libertad de horario</p>
                  </div>
                  <div class="brItem">
                    <div class="imgBr">
                      <img src="@/assets/building.png" alt="">
                    </div>
                    <p>Respaldo de una gran empresa</p>
                  </div>
                  <div class="brItem">
                    <div class="imgBr">
                      <img src="@/assets/hand.png" alt="">
                    </div>
                    <p>Genera amplia cartera de clientes</p>
                  </div>
                  <div class="brItem">
                    <div class="imgBr">
                      <img src="@/assets/finan.png" alt="">
                    </div>
                    <p>Aumentar el potencial de ventas</p>
                  </div>
                  
                </div>
              </div>
            </div>
            
        </div>
      </section>
      
 <div id="cookies" class="cookies-banner" v-show="alertCookie">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
          <p class="cook-text">Este sitio utiliza cookies para mejorar la experiencia de nuestros Socios. Cada vez que utilizas nuestro sitio web, 
				autorizas el uso de cookies para almacenar información y generar estadísticas de audiencia analizando tu navegación. 
				Revisa nuestro Aviso de Cookies para más información.</p>
        </div>
         <div role="group" class="col-md-3 gbtn">
           <button  class=" gdpr-accept btn-cazul" @click="aceptoCookie()">Acepto</button> 
            <router-link to="/aviso-de-privacidad">
              <button type="button" class=" gdpr-review">Revisar</button>
            </router-link>
        </div>
      </div>
    </div>
  </div>
<Transition name="fade">
  <div class="modaln" v-show="modal">
    <div class="modaln-content">
      <span class="cerrarbtn" @click="modal=false;ponerCookie('preventiva','true',3000000)">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
        </svg>
      </span>
      <div class="imagen">
        <img src="@/assets/fraude.jpg" alt="Fraude en credix" style="width:100%">
      </div>
    </div>
 </div>  
</Transition>

</div>



  
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  components:{
    VueperSlides, VueperSlide
  },
  methods:{
    verLinea(){
      switch(this.mLineas){
        case'1':this.$router.push('/credito-revolvente');break;
        case'2':this.$router.push('/credito-simple');break;
        case'3':this.$router.push('/credito-construcredix');break;
        case'4':this.$router.push('/factoraje-financiero');break;
        case'7':this.$router.push('/factoraje-pago-a-proveedores');break;
        case'5':this.$router.push('/fideicomiso-de-garantia-credito');break;
        case'6':this.$router.push('/fideicomiso-de-garantia-desarrollo-inmobiliario');break;
        
      }
    },
    aceptoCookie(){
      this.alertCookie = false;
      this.ponerCookie("cookredix","true",3000000)
    },
    comprobarCookie(){
      const itemStr = localStorage.getItem("cookredix");
      if (!itemStr) {
        this.alertCookie = true;
        return null;
      }
      let item = JSON.parse(itemStr);
      const now = new Date();
      if(now.getTime()>item.expiry){localStorage.removeItem("cookredix");}
    },
    ponerCookie(key,valor,tiempo){
      const now = new Date();
      const item = {
					value: valor,
					expiry: now.getTime() + tiempo,
				}
      localStorage.setItem(key, JSON.stringify(item));
    },
    
    destaH(){
        var newHeight = 0;
        if(screen.width < 767){
         newHeight = '380px'
        }else{
          newHeight = '400px'
        }
        
        return newHeight;
      },  
  },
  mounted:function(){
    this.comprobarCookie()
     document.addEventListener('keyup', (e)=>{
      if(e.keyCode === 27){
        this.modal = false
      }
    });
    setTimeout(() => {
      this.modal = true
    },1000);
  },
  data(){
    return{
      isHidden:false,
      mLineas:"",
      modal:false,
      adevertencia:true,
      alertCookie:false,
      breakpoints: {
        1200: {
          visibleSlides:3
        },
        900: {
          visibleSlides:2
        },
        600: {
          visibleSlides:1
        },
        // The order you list breakpoints does not matter, Vueper Slides will sort them for you.
        1100: {
          visibleSlides:3
        }
      },
    }
  }
}
</script>
<style lang="css" scoped>

.modaln{
  display: flex;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(60,60,68,.8313725490196079);
}
.modaln .imagen{
  max-width: 600px;
}
.modaln .modaln-content{
  position: absolute;
}
.modaln .cerrarbtn {
    position: absolute;
    top: -36px;
    color: #fff;
    font-size: 25px;
    right: 0;
    cursor: pointer;
}
.cookies-banner {
  position: fixed;
  bottom: 0;
  width:100%;
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px -1px 6px 0px #00000063;
  padding: 20px 10px;
  backdrop-filter: blur(1px);
  color: #069;
}
.cookies-banner .cook-text {
  font-size: 9pt;
}
.cookies-banner .btn-cazul {
  background: #006699;
  color: #fff;
}
.cookies-banner .gbtn {
  display: flex;
}
.cookies-banner .gbtn .gdpr-accept {
  flex: 1;
  padding: 0.3rem 0;
  width: 100%;
  border: none;
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
}
.cookies-banner .gbtn a {
  flex: 1;
  width: 100%;
  padding: 0.3rem 0;
  height: 100%;
  margin-left: 5px;
}
.cookies-banner .gbtn a button {
  height: 100%;
  width: 100%;
  border: none;
}
.brokers {
  margin-bottom: 4rem;
}
.brokers .brokers-textos {
  padding-right: 5rem;
}
.brokers .brokers-textos h1 {
  color: #171f2a;
  font-size: 25px;
}
.brokers .brokers-textos p {
  margin-bottom: 3rem;
}
.itemsBr {
  display: flex;
  flex-wrap: wrap;
}
.itemsBr .brItem {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 1rem;
}
.itemsBr .brItem .imgBr img {
  width: 40px;
}
.itemsBr .brItem p {
  margin-left: 1rem;
  font-size: 14px;
}
.inicio-banner {
  text-align: center;
}
.inicio-banner img {
  object-fit: cover;
  height: 70vh;
  max-width: 100%;
}
.inicia-solicitud h3 {
  color: #006699;
  text-shadow: 1px 1px 1px #fff;
  font-weight: 600;
  font-size: 2.5rem;
}
.banner-inicial {
  width: 100%;
  position: relative;
  height: 70vh;
}
.textos-inicio {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.texto-inicio-banner {
  text-align: right;
  padding: 0 6rem 0rem 0;
}
.texto-inicio-banner p {
  font-size: 30px;
  color: #006699;
  letter-spacing: 0.3px;
  line-height: 40px;
  margin-bottom: 2rem;
}
.texto-inicio-banner h1 {
  color: #006699;
  font-size: 45px;
  line-height: 52px;
  font-weight: 700;
  padding-bottom: 40px;
}
.texto-inicio-banner h2 {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
  padding-bottom: 30px;
  color: #006699;
}
.servicios .servicio-img img {
  width: 650px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  vertical-align: middle;
  border-style: none;
  border-radius: 30px;
  transform: scaleX(-1);
}
.servicios .servicio-card {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: #32325d40 0 13px 27px -5px, #00000033 0 8px 20px -10px;
  padding: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  
  height: 300px;
}
.servicios .servicio-card .title-servicio {
  color: #006699;
  letter-spacing: 0.07rem;
  font-weight: 700;
  font-size: 20px;
  height: 30px;
  display: flex;
  align-items: center;
}
.servicios .servicio-card p {
  font-size: 15px;
  margin-bottom: 2rem;
  height: 100px;
}
.beneficio {
  margin: 25px 0;
}
.beneficio p {
  margin: 0 0 0 15px;
}
.beneficio p sup {
  font-size: 6.5pt;
}
.beneficios .content-titulo {
  justify-content: flex-start;
  flex-direction: column;
}
.beneficios .content-titulo p {
  text-align: left;
  max-width: 90%;
  font-size: 18px;
}
.comienza-banner {
  color: #171f2a;
}
.comienza-banner h2 {
  font-weight: 200;
}
.card {
  border-radius: 11%;
  width: 90%;
  margin: 0 auto;
  height: 280px;
  padding-bottom: 1rem;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  margin: 2rem 0;
}
.card .text-head {
  margin-top: 1rem;
  display: flex;
}
.card .text-head img {
  width: 70px;
}
.card .texto {
  margin-top: 0.3rem;
  margin-left: 1rem;
}
.card .texto p {
  margin-bottom: 0;
  font-weight: 300;
  color: #757575;
}
.card .texto h3 {
  margin: 0;
  font-size: 23px;
  color: #006699;
  font-weight: 600;
}
.sdescripcion {
  margin-top: 1rem;
  font-size: 14px;
  margin-bottom: 0px;
}
.card-footer {
  background-color: rgba(0, 0, 0, 0);
  padding: 0.8rem 2rem;
  border-top: none;
  color: #006699;
  font-weight: bold;
}
.respaldo {
  background-color: #c1c1c138;
}
.respaldo .textos-respaldo {
  display: flex;
  justify-content: flex-start;
}
.respaldo .textos-respaldo-inicial {
  width: 80%;
  text-align: left;
  padding: 2rem 0px;
}
.respaldo .textos-respaldo-imagen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.respaldo .textos-respaldo-imagen img {
  margin: 0 10px 0 0;
}
.respaldo .textos-respaldo h1 {
  color: #757575;
  font-size: 25px;
  line-height: 52px;
  letter-spacing: 0.5px;
}
.respaldo .textos-respaldo h2 {
  color: #757575;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
  width: 70%;
  margin-bottom: 20px;
}
.respaldo .textos-respaldo span {
  color: #757575;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
}
.respaldo .textos-respaldo button {
  border: 0.5px solid #006699;
  outline: none;
  color: #006699;
  padding: 3px 15px;
  margin-left: 25px;
  border-radius: 15px;
}
.btn-lineas {
  display: flex;
}
.btn-lineas select {
  width: 75%;
}
.cuadro-pasos {
  display: flex;
  width: 90%;
  border-radius: 10px;
  margin: 3rem auto;
  padding: 20px;
  box-shadow: #32325d40 0 13px 27px -5px, #00000033 0 8px 20px -10px;
}
.cuadro-pasos .paso {
  flex: 1;
  border-right: 0.5px solid #757575;
  display: flex;
  flex-direction: column;
  color: #757575;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  height: auto;
}
.cuadro-pasos .paso .cuadro-content {
  display: none;
  padding: 0 1rem;
}
.cuadro-pasos .paso-titulo {
  margin: 1rem 0;
}
.cuadro-pasos .paso-titulo p:nth-child(2) {
  color: #006699;
  font-weight: 800;
}
.cuadro-pasos .paso-titulo p {
  margin: 0;
  text-align: center;
}
.cuadro-pasos .paso .paso-icon {
  background: #fff;
  padding: 10px;
  border-radius: 50%;
}
.cuadro-pasos .paso .paso-icon img {
  fill: #fff;
}
.cuadro-pasos .paso:last-child {
  border-right: 0px solid transparent;
}
.verLineas {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verLineas select:focus-visible {
  outline: none;
}
.restric p {
  font-size: 6.5pt;
}
.bkStar{
   background-image: url('~@/assets/Star2.svg');
   background-repeat: no-repeat;
   background-position: left;
   
}
@media only screen and (max-width: 768px) {
  .inicio-banner {
    text-align: left;
    height: 38vh;
  }
  .modaln .imagen{
  max-width: 400px;
}
  
  .inicio-banner img {
    display: none;
  }
  .beneficios img {
    text-align: center;
  }
  .texto-inicio-banner {
    margin-bottom: 2rem;
    margin-top: -250px;
    z-index: 10;
    padding: 0 1rem;
  }
  .texto-inicio-banner p {
    padding-left: 50%;
    line-height: 15px;
    font-size: 15px;
  }
  .texto-inicio-banner h1 {
    padding-left: 25%;
    line-height: 37px;
    font-size: 35px;
  }
  .texto-inicio-banner h2 {
    padding-left: 10%;
  }
  .respaldo .textos-respaldo .textos-respaldo-inicial {
    width: 100%;
  }
  .respaldo .textos-respaldo .textos-respaldo-inicial button {
    margin-top: 1rem;
  }
  .respaldo .textos-respaldo .textos-respaldo-inicial h2 {
    width: 100%;
  }
  .respaldo .textos-respaldo .textos-respaldo-imagen {
    margin-top: 2rem;
    flex-wrap: wrap;
  }
  .respaldo .textos-respaldo .textos-respaldo-imagen img {
    height: 40px;
  }
  .cuadro-pasos {
    flex-wrap: wrap;
    width: 90%;
  }
  .cuadro-pasos .paso {
    flex: unset;
    border-right: none;
    width: 100%;
  }
  .card {
    width: 70%;
    margin: auto;
  }
  .footer .container {
    width: 100%;
  }
  .footer .logofooter {
    display: block;
  }
  .footer .logofooter .redes-sociales {
    display: block;
    margin-top: 2rem;
  }
  .mobitembor{
    margin-top: 2.4rem;
  }
  .brnImg{
    text-align: right;
  }
}
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}
.enter {
  opacity: 1;
  transform: translateY(0px);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>